<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,    
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      status: '',
      emailConfirmationCode: null,
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.emailConfirmationCode != null) {
      this.emailConfirmationCode = this.$route.query.emailConfirmationCode;        
      this.confirmEmail(); 
      this.$router.push('Login')
    } else {
      this.emailConfirmationCode = null;
    }
  },
  methods: {
    confirmEmail(){
      this.processing = true;  
      axios.get('/User/ConfirmEmail', { params: { emailConfirmationCode: this.emailConfirmationCode}})
      .then(response => {
        this.processing = false;
        if(response.data) {
          setTimeout(() => {
            this.$bvToast.toast("Tu cuenta ha sido confirmada de forma Exitosa", { title: 'Ok', variant: 'success', solid: true, })
          },5)          
        }    
      })
    }
  },
}
</script>
